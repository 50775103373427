import React from "react";

const GAScripts = () => (
  <>
    <script
      async
      src="scripts/GTagManager.js"
      // src="https://www.googletagmanager.com/gtag/js?id=G-KGCZFNLS5Z"
    ></script>
    <script src="scripts/ga-pre.js"></script>
    <script src="scripts/ga.js"></script>
  </>
);

export default GAScripts;
