import { graphql, PageProps } from "gatsby";
import React from "react";
import Column from "../components/ui/Column";
import GAScripts from "../components/GAScripts";
import Layout from "../components/ui/Layout";
import * as classes from "./join.module.scss";

const data = {
  title: "Sign up to stay in touch.",
  introduction:
    "Join a coalition to defend educational freedom and keep politics out of classrooms.",
};

class FormEmbed extends React.PureComponent {
  render() {
    return <iframe src="/join-form/index.html" className={classes.iframe} />;
  }
}

const Disclaimer = () => (
  <div className={classes["disclaimer"]}>
    <p>
      By submitting this form, you are consenting to receive communications via
      email from:{" "}
    </p>
    <address className={classes["address"]}>
      Save New College
      <br />
      5800 Tamiami Trail
      <br />
      Sarasota, FL USA
      <br />
      34243
    </address>
    <p>
      You can revoke your consent to receive emails at any time by using the
      unsubscribe link found at the bottom of every email.
    </p>
  </div>
);

const SignUpPage = () => (
  <Layout>
    <Column>
      <div className={classes["joinPage"]}>
        <h2>{data.title}</h2>
        <p className={classes["introduction"]}>{data.introduction}</p>

        <FormEmbed />

        <Disclaimer />
      </div>
    </Column>
  </Layout>
);

export const query = graphql`
  query IndexPage {
    siteBuildMetadata {
      buildTime
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export const Head = ({ data }: PageProps<Queries.IndexPageQuery>) => (
  <>
    <title>{data.site?.siteMetadata?.title} - Sign Up</title>
    <meta name="description" content="Save New College" />
    <GAScripts />
  </>
);

export default SignUpPage;
